<template>
  <div
    class="filter-bar mb-2">
    <div class="filter-bar-top-container w-100 justify-space-between">
      <v-btn
        class="float-left"
        icon
        variant="flat"
        @click="toggleCollapsed()">
        <v-icon
          v-if="collapsed"
          class="bainbridge-gray--text"
          icon="mdi-chevron-right"/>
        <v-icon
          v-else
          class="bainbridge-gray--text"
          icon="mdi-chevron-down"/>
      </v-btn>
      <div
        v-if="collapsed"
        key="filter-summary"
        class="filter-summary flex-1"
        @click="toggleCollapsed()">
        <span
          v-for="filter in displayedFilters"
          :key="filter.id"
          :class="{
            'disabled-filter-label': !localDashboardFilters.filterIds.includes(filter.id),
          }"
          class="pr-1"
          v-html="displayedFilters[filter.id].filterSummary"/>
      </div>
      <div class="share-filters-container">
        <v-menu
          v-if="!areFiltersDefault"
          v-model="shareMenu"
          :close-on-content-click="false"
          content-class="share-filters-menu"
          max-width="350px"
          min-width="350px"
          max-height="350px"
          z-index="3">
          <template #activator="{ props }">
            <v-btn
              v-bind="props"
              id="share-filters-menu-button"
              color="primary"
              size="small"
              icon
              elevation="0">
              <v-icon
                icon="mdi-link"/>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item-title
                class="mt-2">
                <span class="filter-menu-title px-4">
                  Share Filters
                </span>
              </v-list-item-title>
            </v-list>
            <v-list>
              <v-list-item
                id="share-filters-row">
                <div
                  class="saved-filter-row">
                  Share Filters
                  <v-list-item-action class="ma-0">
                    <ShareButton
                      :content-id="filterSetId"
                      :loading="filterSetIdLoading"
                      path-type="base"
                      content-type="filters"
                      query-param="filter_set"/>
                  </v-list-item-action>
                </div>
              </v-list-item>
              <v-list-item
                id="share-filters-dashboard-row">
                <div
                  class="saved-filter-row">
                  Share Filters and Dashboard
                  <v-list-item-action class="ma-0">
                    <ShareButton
                      :content-id="filterSetId"
                      :loading="filterSetIdLoading"
                      path-type="full"
                      content-type="filters"
                      query-param="filter_set"/>
                  </v-list-item-action>
                </div>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
        <v-menu
          v-model="saveMenu"
          :close-on-content-click="false"
          content-class="save-filters-menu"
          max-width="350px"
          min-width="350px"
          max-height="350px"
          z-index="3">
          <template #activator="{ props }">
            <v-btn
              v-bind="props"
              id="save-filters-menu-button"
              color="primary"
              size="small"
              icon
              elevation="0">
              <v-icon
                size="x-large"
                icon="mdi-filter-variant"/>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item-title class="mt-2">
                <span class="filter-menu-title px-4">
                  Saved Filters
                </span>
              </v-list-item-title>
            </v-list>
            <v-list v-if="!areFiltersDefault">
              <v-list-item>
                <div class="saved-filter-row">
                  <v-text-field
                    ref="currentName"
                    v-model="currentUserFilterSetName"
                    :rules="[(value) => !!value]"
                    :validate-on="true ? 'blur' : undefined"
                    class="filter-name-input align-center mt-0 pt-0 w-100"
                    placeholder="Name current filters"
                    variant="underlined"
                    @keyup.enter="saveFilters"/>
                  <v-list-item-action class="mt-1 mb-0 align-self-start">
                    <v-tooltip location="bottom">
                      <template #activator="{ props }">
                        <v-btn
                          v-bind="props"
                          id="save-button"
                          :loading="!areFiltersSaved"
                          :disabled="!areFiltersSaved || !currentUserFilterSetName || !userFilterSetsLoaded"
                          variant="text"
                          icon
                          @click="saveFilters">
                          <v-icon
                            icon="mdi-content-save"/>
                        </v-btn>
                      </template>
                      <span>Save your filters.</span>
                    </v-tooltip>
                  </v-list-item-action>
                </div>
              </v-list-item>
            </v-list>
            <v-list
              v-if="!hasUserFilterSets"
              class="pb-0">
              <v-list-item>
                You have not saved any filters. {{ areFiltersDefault ? 'Set a filter to get started.' : '' }}
              </v-list-item>
            </v-list>
            <v-list v-else>
              <UserFilterSetRow
                v-for="userFilterSetId in sortedUserFilterSets"
                :key="userFilterSetId"
                :menu-open="saveMenu"
                :user-filter-set="userFilterSets[userFilterSetId]"
                :user-filter-set-id="userFilterSetId"
                :are-filters-default="areFiltersDefault"
                :action-complete="actionComplete"
                @applyFilters="applyFilters"
                @replaceUserFilterSet="replaceUserFilterSet"
                @updateUserFilterSetName="updateUserFilterSetName"
                @updateDefaultUserFilterSet="updateDefaultUserFilterSet"
                @removeUserFilterSet="removeUserFilterSet"/>
            </v-list>
          </v-card>
        </v-menu>
        <v-btn
          v-if="dashboardsLoaded || filterValuesRequired"
          id="run-button"
          :loading="(!filtersUpdated && !dashboardsLoaded && !filterValuesRequired) || stoppingRun"
          :disabled="(!filtersUpdated && !dashboardsLoaded) || filterValuesRequired || stoppingRun"
          :color="filtersUpdated ? 'secondary' : 'primary'"
          class="text-white"
          variant="flat"
          @click="runFilters()">
          {{ filtersUpdated ? 'Run' : 'Refresh' }}
        </v-btn>
        <v-btn
          v-else-if="!filterValuesRequired"
          id="stop-button"
          :loading="stoppingRun"
          :disabled="stoppingRun"
          color="warning"
          class="text-white"
          variant="flat"
          @click="stopDashboardRun()">
          Stop
        </v-btn>
      </div>
    </div>
    <div
      v-if="!collapsed"
      key="filter-bar"
      class="filter-bar-bottom-container h-100">
      <div class="clearfix"/>
      <span class="section-label subheading font-weight-light">
        Filters
      </span>
      <v-divider class="ma-2"/>
      <component
        :is="filterComponent[filter.type]"
        v-for="filter in displayedFilters"
        :key="filter.id"
        :filter-id="filter.id"
        :loading="filter.autocompleteLoading"
        :label="filter.dashboardDisplayName ? filter.dashboardDisplayName : filter.displayName"
        :value-required="filter.required ? filter.required : filter.required"
        :passed-value="allFilterValues[filter.id] ? cloneDeep(allFilterValues[filter.id].value) : {}"
        :possible-values="cloneDeep(filter.possibleValues)"
        :is-active="localDashboardFilters.filterIds.includes(filter.id)"
        :multiple="filter.multiple !== null ? filter.multiple : true"
        @valueChanged="onValueChanged"
        @removeFilter="onRemoveFilter"/>
      <span
        v-if="hasOptions"
        class="mt-4">
        <span class="section-label subheading font-weight-light">
          Options
        </span>
        <v-divider class="ma-2"/>
        <component
          :is="filterComponent[filter.type]"
          v-for="filter in displayedOptions"
          :key="filter.id"
          :filter-id="filter.id"
          :loading="filter.autocompleteLoading"
          :label="filter.dashboardDisplayName ? filter.dashboardDisplayName : filter.displayName"
          :passed-value="allFilterValues[filter.id] ? cloneDeep(allFilterValues[filter.id].value) : {}"
          :possible-values="cloneDeep(filter.possibleValues)"
          :value-required="filter.required ? filter.required : filter.required"
          :default-value="filter.dashboardDefaultValue ? filter.dashboardDefaultValue : filter.defaultValue"
          :is-active="localDashboardFilters.filterIds.includes(filter.id)"
          :multiple="filter.multiple !== null ? filter.multiple : true"
          @valueChanged="onValueChanged"
          @removeFilter="onRemoveFilter"/>
      </span>
      <v-btn
        class="float-right"
        variant="flat"
        @click="resetFilters">
        Reset
      </v-btn>
    </div>

    <div class="clearfix"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import { EventLog } from '@/lib/event-log';
import { FilterSet } from '@/lib/filter-set';
import { UserFilterSet } from '@/lib/user-filter-set';

import {
  IS_BLANK,
  IS_NULL,
  IS_NOT_BLANK,
  IS_NOT_NULL,
} from '@/lib/constants';

import ShareButton from '@/components/utils/ShareButton.vue';
import {
  BooleanFilter, DateFilter, NumericFilter, TextFilter, SelectFilter,
} from './filters/index';
import UserFilterSetRow from './UserFilterSetRow/UserFilterSetRow.vue';

export default {
  name: 'FilterBar',
  components: {
    BooleanFilter,
    DateFilter,
    NumericFilter,
    SelectFilter,
    TextFilter,
    UserFilterSetRow,
    ShareButton,
  },
  props: {
    dashboardsLoaded: {
      type: Boolean,
      required: true,
    },
    dashboardFilters: {
      type: Object,
      default: () => {},
    },
    areFiltersSaved: {
      type: Boolean,
      default: () => true,
    },
    actionComplete: {
      type: Boolean,
      default: () => true,
    },
    stoppingRun: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      collapsed: true,
      localDashboardFilters: new FilterSet(),
      localFilterSet: new FilterSet(),
      filterComponent: {
        date: 'DateFilter',
        number: 'NumericFilter',
        text: 'TextFilter',
        boolean: 'BooleanFilter',
        select: 'SelectFilter',
      },
      saveMenu: false,
      currentUserFilterSetName: '',
      shareMenu: false,
      filterSetId: '',
      filterSetIdLoading: false,
    };
  },
  computed: {
    displayedFilters() {
      const displayedFilters = new FilterSet({
        ...cloneDeep(this.localDashboardFilters.filtersNoOptions),
        ...cloneDeep(this.localFilterSet.filtersNoOptions),
      });
      displayedFilters.filterIds.forEach((filterId) => {
        if (filterId in this.localFilterSet) {
          displayedFilters[filterId].value = this.localFilterSet[filterId].value;
          displayedFilters[filterId].possibleValues = this.localFilterSet[filterId].possibleValues;
        } else if (filterId in this.localDashboardFilters) {
          displayedFilters[filterId].value = null;
          displayedFilters[filterId].possibleValues = this.localDashboardFilters[filterId].possibleValues;
        } else {
          displayedFilters[filterId].value = null;
          displayedFilters[filterId].possibleValues = this.filters[filterId].possibleValues;
        }
      });
      return displayedFilters;
    },
    displayedOptions() {
      const displayedOptions = new FilterSet({
        ...cloneDeep(this.localDashboardFilters.filterOptions),
        ...cloneDeep(this.localFilterSet.filterOptions),
      });
      displayedOptions.filterIds.forEach((filterId) => {
        if (filterId in this.localFilterSet) {
          displayedOptions[filterId].value = this.localFilterSet[filterId].value;
          displayedOptions[filterId].possibleValues = this.localFilterSet[filterId].possibleValues;
        } else if (filterId in this.localDashboardFilters) {
          displayedOptions[filterId].value = null;
          displayedOptions[filterId].possibleValues = this.localDashboardFilters[filterId].possibleValues;
        } else {
          displayedOptions[filterId].value = null;
          displayedOptions[filterId].possibleValues = this.filters[filterId].possibleValues;
        }
      });
      return displayedOptions;
    },
    hasOptions() {
      return !this.displayedOptions.isEmpty;
    },
    allFilterValues() {
      const filterValues = new FilterSet();
      const filterIds = [...this.filterDefaults.filterIds, ...this.localFilterSet.filterIds];
      filterIds.forEach((filterId) => {
        if (this.localFilterSet[filterId]) {
          filterValues[filterId] = this.localFilterSet[filterId];
        } else {
          filterValues[filterId] = this.filterDefaults[filterId];
        }
      });
      return filterValues;
    },
    // Tracks if filters have been updated since last run
    filtersUpdated() {
      return !this.localFilterSet.filtersEqual(this.filterSet.filtersNoHiddens);
    },
    // Tracks if filters are set to their default values
    areFiltersDefault() {
      return this.localFilterSet.isEmpty;
    },
    hasUserFilterSets() {
      return !this.userFilterSets.isEmpty;
    },
    sortedUserFilterSets() {
      return this.userFilterSets.sortedFilterIds;
    },
    filterValuesRequired() {
      const { filtersWithRequiredValues } = this.filters;
      const displayed = { ...this.displayedFilters, ...this.displayedOptions };
      const valueOptions = [IS_BLANK, IS_NULL, IS_NOT_BLANK, IS_NOT_NULL];
      return Object.keys(filtersWithRequiredValues).some((key) => (displayed[key] && (!displayed[key].value ||
        (displayed[key].value && !valueOptions.includes(displayed[key].value.option) && !displayed[key].value.value))));
    },
    ...mapGetters([
      'filterDefaults',
      'filters',
      'filterSet',
      'userFilterSets',
      'userFilterSetsLoaded',
      'userHasCapability',
    ]),
  },
  watch: {
    filterSet: {
      handler() {
        this.localFilterSet = cloneDeep(this.filterSet.filtersNoHiddens);
      },
      deep: true,
      immediate: true,
    },
    dashboardFilters: {
      handler(newValue, prevValue) {
        this.localDashboardFilters = cloneDeep(this.dashboardFilters);
        if (newValue && !prevValue) {
          if (this.filterValuesRequired) {
            this.collapsed = false;
          } else {
            this.collapsed = true;
          }
        }
      },
      deep: true,
      immediate: true,
    },
    localFilterSet: {
      handler() {
        this.filterSetIdLoading = true;
        this.localFilterSet.saveFilterSet().then((response) => {
          this.filterSetId = response.success;
          this.filterSetIdLoading = false;
        });
      },
      deep: true,
      immediate: true,
    },
    saveMenu: {
      handler() {
        if (!this.saveMenu && !this.areFiltersDefault) {
          this.$refs.currentName.reset();
        }
      },
    },
  },
  mounted() {
    if (this.filterValuesRequired) {
      this.collapsed = false;
    }
  },
  methods: {
    cloneDeep(obj) {
      return cloneDeep(obj);
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
    },
    onValueChanged(changes) {
      const displayedFiltersAndOptions = { ...this.displayedFilters, ...this.displayedOptions };
      const filter = cloneDeep(displayedFiltersAndOptions[changes.key]);
      this.localFilterSet[changes.key] = filter;
      this.localFilterSet[changes.key].value = changes.value;

      // Refresh possible filters' values that depend on filter
      this.refreshAutocomplete({
        facetId: changes.key,
        filterValues: this.allFilterValues,
      });
    },
    onRemoveFilter(filterId) {
      delete this.localFilterSet[filterId];
      // Refresh possible filters' values that depend on filter
      this.localFilterSet.filterIds.forEach((id) => {
        this.refreshAutocomplete({
          facetId: id,
          filterValues: this.allFilterValues,
        });
      });
    },
    refreshAutocomplete(filterData) {
      this.displayedFilters.facetedFilterIds(filterData.facetId).forEach((filterId) => {
        if (this.localFilterSet[filterId]) {
          this.localFilterSet[filterId].autocompleteLoading = true;
        }
        if (this.localDashboardFilters[filterId]) {
          this.localDashboardFilters[filterId].autocompleteLoading = true;
        }
        const { filterValues } = filterData;
        const subject = this.displayedFilters[filterId].dashboardAutocompleteSubject ||
          this.displayedFilters[filterId].autocompleteSubject;
        this.$services.autocomplete
          .getAutocompleteResults(subject, this.displayedFilters.autocompleteQuery(filterId, filterValues))
          .then((results) => {
            const hasMappedValues = results.some((result) => typeof result === 'object');
            const possibleValues = hasMappedValues ? results.map((result) => Object.values(result)[0]) : results;
            if (this.localFilterSet[filterId]) {
              this.localFilterSet[filterId].possibleValues = possibleValues;
              this.localFilterSet[filterId].autocompleteLoading = false;
            }
            if (this.localDashboardFilters[filterId]) {
              this.localDashboardFilters[filterId].possibleValues = possibleValues;
              this.localDashboardFilters[filterId].autocompleteLoading = false;
            }
          });
      });
    },
    runFilters() {
      const forceRefresh = !this.filtersUpdated;
      this.$emit('changeFilters', { filterSet: this.localFilterSet, filterSetId: this.filterSetId, forceRefresh });

      this.collapsed = true;
    },
    resetFilters() {
      // Refresh possible filters' values that depend on filter
      this.localFilterSet.filterIds.forEach((filterId) => {
        this.refreshAutocomplete({
          facetId: filterId,
          filterValues: new FilterSet(),
        });
      });
      this.localFilterSet = new FilterSet();
    },
    saveFilters() {
      if (!this.currentUserFilterSetName) {
        return this.$refs.currentName.validate(true);
      }
      const userFilterSet = new UserFilterSet();
      userFilterSet.filters = cloneDeep(this.localFilterSet);
      userFilterSet.name = cloneDeep(this.currentUserFilterSetName);
      this.currentUserFilterSetName = '';
      this.$refs.currentName.reset();
      return this.$emit('saveFilters', userFilterSet);
    },
    applyFilters(userFilterSetId) {
      this.resetFilters();
      const filterSet = cloneDeep(this.userFilterSets[userFilterSetId].filters);
      filterSet.filterIds.forEach((filterId) => {
        this.localFilterSet[filterId] = filterSet[filterId];

        // Refresh possible filters' values that depend on filter
        this.refreshAutocomplete({
          facetId: filterId,
          filterValues: this.allFilterValues,
        });
      });
      const loggingData = new EventLog({
        event: 'filter_set.apply',
        filterSetId: this.userFilterSets[userFilterSetId].filterSetId,
        userFilterSetId,
        userFilterSetName: this.userFilterSets[userFilterSetId].name,
      });
      this.$services.users.postTrackingLog(loggingData);
    },
    updateUserFilterSetName(updates) {
      const updatedUserFilterSet = new UserFilterSet();
      updatedUserFilterSet.filters = cloneDeep(this.userFilterSets[updates.userFilterSetId].filters);
      updatedUserFilterSet.name = cloneDeep(updates.userFilterSetName);
      this.$emit('replaceUserFilterSet', {
        userFilterSetId: updates.userFilterSetId,
        userFilterSet: updatedUserFilterSet,
      });
    },
    updateDefaultUserFilterSet(userFilterSetId) {
      this.$emit('updateDefaultUserFilterSet', userFilterSetId);
    },
    replaceUserFilterSet(userFilterSetId) {
      const updatedUserFilterSet = new UserFilterSet();
      updatedUserFilterSet.filters = cloneDeep(this.localFilterSet);
      updatedUserFilterSet.name = cloneDeep(this.userFilterSets[userFilterSetId].name);
      this.$emit('replaceUserFilterSet', {
        userFilterSetId,
        userFilterSet: updatedUserFilterSet,
      });
    },
    removeUserFilterSet(userFilterSetId) {
      this.$emit('removeUserFilterSet', userFilterSetId);
    },
    stopDashboardRun() {
      this.$emit('stopDashboardRun');
    },
  },
};
</script>

<style lang="scss">

.saved-filter-row {
  display: flex;
  align-items: center;
}

.filter-label {
  align-content: center;
  padding: 6px 0 6px 1rem;
}

.filter-bar {
  @import "@/sass/colors.scss";
  max-height: 70%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $bainbridge-gray-light;
  background-color: #ffffff;
  z-index: 2;

  .filter-bar-top-container {
    display: flex;
  }
  .filter-summary {
    cursor: pointer;
    float: left;
    padding: 16px;
    color: $bainbridge-gray;
  }
  .section-label {
    opacity: .75;
    padding-left: 14px;
    font-size: 16px;
  }
  .disabled-filter-label {
    opacity: .4;
  }
  .filter-bar-bottom-container {
    margin-bottom: 5px;
    overflow: auto;
  }
  .filter-name, .option-name {
    font-size: 14px;
  }
  .v-chip__content {
    font-size: 13px;
  }
}

.filter-menu-title {
  font-size: 20px !important;
}

.save-filters-menu {
  .v-input__slot {
    margin-bottom: 1px;
  }
  .v-text-field__details {
    display: none;
  }
}

.share-filters-container {
  padding-right: 1rem;
}

#run-button {
  width: 82px;
}
</style>
