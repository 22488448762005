import cloneDeep from 'lodash.clonedeep';

import { removePrivateKeysFromObj } from '@/lib/compare';
import { getPreviousDate } from '@/lib/date';
import { Filter } from '@/lib/filter';
import { FilterSet } from '@/lib/filter-set';
import { FilterSetCollection } from '@/lib/filter-set-collection';
import { UserFilterSet } from '@/lib/user-filter-set';
import router from '@/router/index';
import AutocompleteService from '@/services/autocomplete';
import FiltersService from '@/services/filters';

export default {
  state: {
    defaultUserFilterSet: new UserFilterSet(),
    filters: new FilterSet(),
    filterSetId: null,
    filtersLoaded: false,
    userFilterSets: new FilterSetCollection(),
    userFilterSetsLoaded: false,
  },
  mutations: {
    RESET_FILTERS(state) {
      state.defaultUserFilterSet = new UserFilterSet();
      state.filters = new FilterSet();
      state.filtersLoaded = false;
      state.filterSetId = null;
      state.userFilterSets = new FilterSetCollection();
      state.userFilterSetsLoaded = false;
    },
    SET_DEFAULT_USER_FILTER_SET(state, defaultUserFilterSet) {
      state.defaultUserFilterSet = defaultUserFilterSet;
    },
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
    SET_FILTER_SET_ID(state, filterSetId) {
      state.filterSetId = filterSetId;
    },
    SET_FILTERS_LOADED(state, filtersLoaded) {
      state.filtersLoaded = filtersLoaded;
    },
    SET_FILTER_VALUE(state, filterData) {
      state.filters[filterData.id][filterData.key] = filterData.value;
    },
    SET_USER_FILTER_SETS(state, userFilterSets) {
      state.userFilterSets = userFilterSets;
    },
    SET_USER_FILTER_SETS_LOADED(state, userFilterSetsLoaded) {
      state.userFilterSetsLoaded = userFilterSetsLoaded;
    },
  },
  actions: {
    refreshFacetedFilters({
      dispatch, state, getters,
    }, filterId) {
      state.filters.facetedFilterIds(filterId).forEach((facetId) => {
        dispatch('setFilterValue', { id: facetId, key: 'autocompleteLoading', value: true });
        const filterValues = new FilterSet();
        const filterIds = [...getters.filterDefaults.filterIds, ...getters.filterSet.filterIds];
        filterIds.forEach((f) => {
          if (getters.filterSet[f]) {
            filterValues[f] = getters.filterSet[f];
          } else {
            filterValues[f] = getters.filterDefaults[f];
          }
        });
        const subject = state.filters[facetId].dashboardAutocompleteSubject ||
          state.filters[facetId].autocompleteSubject;
        AutocompleteService
          .getAutocompleteResults(subject, state.filters.autocompleteQuery(facetId, filterValues))
          .then((results) => {
            const hasMappedValues = results.some((result) => typeof result === 'object');
            if (hasMappedValues) {
              const possibleValues = results.map((result) => Object.values(result)[0]);
              dispatch('setFilterValue', { id: facetId, key: 'possibleValues', value: possibleValues });
            } else {
              dispatch('setFilterValue', { id: facetId, key: 'possibleValues', value: results });
            }
            dispatch('setFilterValue', { id: facetId, key: 'autocompleteLoading', value: false });
          });
      });
    },
    resetFilters({ commit }) {
      commit('RESET_FILTERS');
    },
    setDefaultUserFilterSet({ commit }, defaultUserFilterSet) {
      commit('SET_DEFAULT_USER_FILTER_SET', defaultUserFilterSet);
    },
    setFilterAutocomplete({ dispatch, commit, state }, filterId) {
      const filter = state.filters[filterId];
      if (filter && (filter.autocompleteSubject || filter.dashboardAutocompleteSubject)) {
        dispatch('setFilterValue', { id: filterId, key: 'autocompleteLoading', value: true });
        const subject = filter.dashboardAutocompleteSubject || filter.autocompleteSubject;
        const filterQuery = {};
        if (filter.autocompleteMappedField) {
          filterQuery.mapped = filter.autocompleteMappedField;
        }
        if (filter.autocompleteFieldOverride) {
          filterQuery.field_override = filter.autocompleteFieldOverride;
        }
        AutocompleteService.getAutocompleteResults(subject, filterQuery).then((results) => {
          const hasMappedValues = results.some((result) => typeof result === 'object');
          if (hasMappedValues) {
            dispatch('setFilterValue', { id: filterId, key: 'mappedValues', value: results });
            const possibleValues = results.map((result) => Object.values(result)[0]);
            dispatch('setFilterValue', { id: filterId, key: 'possibleValues', value: possibleValues });
          } else {
            dispatch('setFilterValue', { id: filterId, key: 'possibleValues', value: results });
          }
          dispatch('setFilterValue', { id: filterId, key: 'autocompleteLoading', value: false });
        });
      }
    },
    setFilters({
      dispatch, commit, state, getters,
    }, initialLoad) {
      return new Promise((resolve, reject) => {
        if (!initialLoad) {
          let filtersChanged = false;
          state.filters.filterIds.forEach((filterId) => {
            const filter = state.filters[filterId];
            if (!filter.isOption) {
              dispatch('setFilterValue', { id: filterId, key: 'mappedValues', value: null });
              dispatch('setFilterValue', { id: filterId, key: 'possibleValues', value: null });
            }
            if (state.filterSetId) {
              const filterValueSet = filterId in getters.filterSet;
              const unsetValue = filterValueSet && filter.tenantSpecific;
              if (unsetValue) {
                dispatch('setFilterValue', { id: filterId, key: 'value', value: null });
                dispatch('refreshFacetedFilters', filterId);
                filtersChanged = true;
              }
            }
          });
          if (filtersChanged && getters.filterSet && !getters.filterSet.isEmpty) {
            getters.filterSet.saveFilterSet().then((response) => {
              dispatch('setFilterSetId', response.success);
              dispatch('setFiltersLoaded', true);
              resolve();
            });
          } else if (filtersChanged) {
            dispatch('setFilterSetId', null);
            dispatch('setFiltersLoaded', true);
            resolve();
          } else {
            dispatch('setFiltersLoaded', true);
            resolve();
          }
        } else {
          FiltersService.getFilters(true).then((filters) => {
            commit('SET_FILTERS', new FilterSet());
            const storeFilters = new FilterSet();
            filters.forEach((f) => {
              const filter = new Filter();
              if (f.default_value) {
                // If a date filter has a default value that is based on local time, determine default value
                if (f.default_value.localTime) {
                  f.default_value.primaryDateField = getPreviousDate(f.default_value.primaryDateField);
                  f.default_value.secondaryDateField = getPreviousDate(f.default_value.secondaryDateField);
                  delete f.default_value.localTime;
                }
              }
              filter.setProps(f);
              storeFilters[filter.id] = filter;
            });
            commit('SET_FILTERS', storeFilters);
            dispatch('setFiltersLoaded', true);
            resolve();
          }).catch(() => {
            reject();
          });
        }
      });
    },
    setFiltersLoaded({ commit }, filtersLoaded) {
      commit('SET_FILTERS_LOADED', filtersLoaded);
    },
    setFilterSetId({ commit }, filterSetId) {
      commit('SET_FILTER_SET_ID', filterSetId);
    },
    setFilterValue({ commit }, filterData) {
      commit('SET_FILTER_VALUE', filterData);
    },
    setUserFilterSets({
      dispatch, commit, state, rootGetters, getters,
    }, initialLoad) {
      return new Promise((resolve, reject) => {
        if (!initialLoad) {
          if (!state.defaultUserFilterSet.isEmpty && !router.currentRoute.value.query.filter_set) {
            if (state.defaultUserFilterSet.filters.filterIds.every((f) => getters.filters.filterIds.includes(f))) {
              state.filters.filterIds.forEach((filterId) => {
                const filter = filterId in state.defaultUserFilterSet.filters;
                const filterValue = filter ? cloneDeep(state.defaultUserFilterSet.filters[filterId].value) : null;
                const changed = state.filters[filterId].value !== removePrivateKeysFromObj(filterValue);
                if (changed) {
                  dispatch('setFilterValue', { id: filterId, key: 'value', value: removePrivateKeysFromObj(filterValue) });
                  dispatch('refreshFacetedFilters', filterId);
                }
              });
              dispatch('setFilterSetId', state.defaultUserFilterSet.filterSetId);
            }
          }
          dispatch('setUserFilterSetsLoaded', true);
          resolve();
        } else {
          FiltersService.getUserFilterSets(rootGetters.user.id, true).then((sets) => {
            const userFilterSets = {};
            sets.forEach((s) => {
              if (Object.keys(s.data.filters).every((f) => getters.cumulativeFilters.filterIds.includes(f))) {
                userFilterSets[s.id] = new UserFilterSet(
                  s.created,
                  s.data.id,
                  new FilterSet(),
                  s.id,
                  s.is_default,
                  s.name,
                  rootGetters.user.id,
                );
                Object.keys(s.data.filters).forEach((filterId) => {
                  const filter = s.data.filters[filterId];
                  const filterDef = filterId in getters.cumulativeFilters;
                  if (filterDef) {
                    userFilterSets[s.id].filters[filterId] = new Filter();
                    userFilterSets[s.id].filters[filterId].setProps(getters.cumulativeFilters[filterId]);
                    userFilterSets[s.id].filters[filterId].value = removePrivateKeysFromObj(filter);
                  }
                });
                if (s.is_default) {
                  dispatch('setDefaultUserFilterSet', userFilterSets[s.id]);
                  if (!router.currentRoute.value.query.filter_set && (
                    userFilterSets[s.id].filters.filterIds.every((f) => getters.filters.filterIds.includes(f))
                  )) {
                    state.filters.filterIds.forEach((filterId) => {
                      const filter = filterId in userFilterSets[s.id].filters;
                      const filterValue = filter ? cloneDeep(userFilterSets[s.id].filters[filterId].value) : null;
                      const changed = state.filters[filterId].value !== removePrivateKeysFromObj(filterValue);
                      if (changed) {
                        dispatch('setFilterValue', {
                          id: filterId,
                          key: 'value',
                          value: removePrivateKeysFromObj(filterValue),
                        });
                        dispatch('refreshFacetedFilters', filterId);
                      }
                    });
                    dispatch('setFilterSetId', s.data.id);
                  }
                }
              }
            });
            if (sets.every((set) => !set.is_default)) {
              dispatch('setDefaultUserFilterSet', new UserFilterSet());
            }
            commit('SET_USER_FILTER_SETS', new FilterSetCollection(userFilterSets));
            dispatch('setUserFilterSetsLoaded', true);
            resolve();
          }).catch(() => {
            reject();
          });
        }
      });
    },
    setUserFilterSetsLoaded({ commit }, userFilterSetsLoaded) {
      commit('SET_USER_FILTER_SETS_LOADED', userFilterSetsLoaded);
    },
  },
  getters: {
    cumulativeFilters: (state) => state.filters,
    defaultUserFilterSet: (state) => state.defaultUserFilterSet,
    filterDefaults: (state) => state.filters.filtersCapableOf.filtersWithDefaults,
    filters: (state) => state.filters.filtersCapableOf,
    filterSet: (state) => state.filters.filtersCapableOf.filtersWithValues,
    filterSetId: (state) => state.filterSetId,
    filtersLoaded: (state) => state.filtersLoaded,
    userFilterSets: (state) => state.userFilterSets.filterSetsCapableOf,
    userFilterSetsLoaded: (state) => state.userFilterSetsLoaded,
  },
};
